import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

const monkeyUrl = new URL('../assets/Animation.glb', import.meta.url);
const container = document.getElementById('three-js-container');

// Enable antialiasing and adjust pixel ratio
const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.setPixelRatio(window.devicePixelRatio * 2); // Adjust this value as needed
renderer.setSize(container.offsetWidth, container.offsetHeight);
container.appendChild(renderer.domElement);

// const canvas = renderer.domElement;
// canvas.classList.add('custom-canvas');

const scene = new THREE.Scene();

// Adjust camera settings as needed
const camera = new THREE.OrthographicCamera(
    container.offsetWidth / -4,
    container.offsetWidth / 0.26,
    container.offsetHeight / 0.33,
    container.offsetHeight / -2,
    0.1,
    1000
);
camera.position.set(0, 0, 200);
camera.lookAt(0, 0, 0);
scene.scale.set(2800, 2800, 2800);

renderer.setClearColor(0xA3A3A3, 0); // Set alpha to 1

const grid = new THREE.GridHelper(30, 30);
// scene.add(grid);

const ambientLight = new THREE.AmbientLight(0xffffff, 2);
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 1.8);
directionalLight.position.set(0, 1, 0);
scene.add(directionalLight);

const assetLoader = new GLTFLoader();
let mixer;

assetLoader.load(monkeyUrl.href, function (gltf) {
    const model = gltf.scene;
    scene.add(model);
    mixer = new THREE.AnimationMixer(model);
    const clips = gltf.animations;

    clips.forEach(function (clip) {
        const action = mixer.clipAction(clip);
        action.play();
        action.loop = THREE.LoopOnce;
        action.clampWhenFinished = true;
    });
}, undefined, function (error) {
    console.error(error);
});

const clock = new THREE.Clock();

function animate() {
    if (mixer) mixer.update(clock.getDelta());
    renderer.render(scene, camera);
}

renderer.setAnimationLoop(animate);

window.addEventListener('resize', function () {
    camera.aspect = container.offsetWidth / container.offsetHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(container.offsetWidth, container.offsetHeight);
});

const homeSection = document.getElementById('home');
const navbarSection = document.getElementById('navbar');
const homeSectionHeight = homeSection.offsetHeight - navbarSection.offsetHeight;

const hamburgerIcon = document.querySelector('.hamburger-icon');
const closeIcon = document.querySelector('.close-icon');
const navbarLinks = document.querySelector('.navbar-links');
const navLinks = document.querySelector('.nav-links');
const logo = document.querySelector('.logo');

// Add 'centered' class to the logo initially
logo.classList.add('centered');

// Add event listener to hamburger icon
hamburgerIcon.addEventListener('click', () => {
    navLinks.classList.toggle('custom-visibility-class');
});

closeIcon.addEventListener('click', () => {
    navLinks.classList.remove('custom-visibility-class');
});

function updateNavLinksVisibility() {
    if (window.scrollY > homeSectionHeight || window.innerWidth < 1330) {
        if (navbarLinks) {
            navbarLinks.classList.add('visible');
        }
        if (logo) {
            logo.classList.remove('centered');
        }
    } else {
        if (navbarLinks) {
            navbarLinks.classList.remove('visible');
        }
        if (logo) {
            logo.classList.add('centered');
        }
    }
}


// Initial check when the page loads
updateNavLinksVisibility();

// Scroll event listener
window.addEventListener('scroll', () => {
    updateNavLinksVisibility();
});

// Resize event listener
window.addEventListener('resize', () => {
    updateNavLinksVisibility();
});

document.addEventListener('DOMContentLoaded', function () {
    var navLinks = document.querySelectorAll('.navbar-link');
    navLinks.forEach(function (link) {
        link.addEventListener('click', function () {
            navLinks.forEach(function (navLink) {
                navLink.classList.remove('white');
            });
            this.classList.toggle('white');
        });
    });
});

$(document).ready(function () {
    $('.carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        centerMode: true,
        centerPadding: '0',
        autoplay: true,
        autoplaySpeed: 3000,
        appendDots: $('.dots-container'),
        customPaging: function (slider, i) {
            return '<button class="dot"></button>';
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});
